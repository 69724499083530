.ConfirmSignupForm_root__1XqOi {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.ConfirmSignupForm_root__1XqOi {
    justify-content: space-between
}
  }

.ConfirmSignupForm_name__HnNnU {
  display: flex;
  justify-content: space-between;
  margin-top: 24px
}

@media (min-width: 768px) {

.ConfirmSignupForm_name__HnNnU {
    margin-top: 32px
}
  }

.ConfirmSignupForm_firstNameRoot__15_kc {
  width: calc(34% - 9px);
}

.ConfirmSignupForm_lastNameRoot__2-iDX {
  width: calc(66% - 9px);
}

.ConfirmSignupForm_password__2Mhhl {
    /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media (min-width: 768px) {

.ConfirmSignupForm_password__2Mhhl {
  margin-top: 32px;
}
    }

.ConfirmSignupForm_bottomWrapper__2m2Yn {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.ConfirmSignupForm_bottomWrapper__2m2Yn {
  margin-top: 96px;
}
    }

.ConfirmSignupForm_bottomWrapperText__32smL {
  margin-top: -24px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {

.ConfirmSignupForm_bottomWrapperText__32smL {
  padding-bottom: 0;
}
    }

.ConfirmSignupForm_termsText__jCatT {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.ConfirmSignupForm_termsText__jCatT {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.ConfirmSignupForm_termsText__jCatT {
  color: var(--matterColorAnti);
  margin: 0;
}

@media (min-width: 768px) {

.ConfirmSignupForm_termsText__jCatT {
  margin: 0;
}
    }

.ConfirmSignupForm_termsLink__2pHsj {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.ConfirmSignupForm_termsLink__2pHsj {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.ConfirmSignupForm_termsLink__2pHsj {
  color: var(--matterColor);
  margin: 0
}

@media (min-width: 768px) {

.ConfirmSignupForm_termsLink__2pHsj {
  margin: 0
}
    }

.ConfirmSignupForm_termsLink__2pHsj:hover {
    text-decoration: underline;
    cursor: pointer;
  }

.LoginForm_root__2HjMG {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.LoginForm_root__2HjMG {
    justify-content: space-between
}
  }

.LoginForm_password__WeCIl {
    /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media (min-width: 768px) {

.LoginForm_password__WeCIl {
  margin-top: 32px;
}
    }

.LoginForm_bottomWrapper__2_MaR {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.LoginForm_bottomWrapper__2_MaR {
  margin-top: 96px;
}
    }

.LoginForm_bottomWrapper__2_MaR {
  margin-top: 50px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */

.LoginForm_bottomWrapperText__8wXCJ {
  margin-top: -24px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {

.LoginForm_bottomWrapperText__8wXCJ {
  padding-bottom: 0;
}
    }

.LoginForm_recoveryLink__1DncX {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.LoginForm_recoveryLink__1DncX {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.LoginForm_recoveryLink__1DncX {
  color: var(--matterColor);
  margin: 0;
}

@media (min-width: 768px) {

.LoginForm_recoveryLink__1DncX {
  margin: 0;
}
    }

.LoginForm_recoveryLinkInfo__1y9Ni {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.LoginForm_recoveryLinkInfo__1y9Ni {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.LoginForm_recoveryLinkInfo__1y9Ni {
  color: var(--matterColorAnti);
  margin: 0;
}

@media (min-width: 768px) {

.LoginForm_recoveryLinkInfo__1y9Ni {
  margin: 0;
}
    }

.LoginForm_recaptcha__3MzJ-{
  margin-top: 30px;
}
.SignupForm_root__1VZ4l {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  height: 100%;

  justify-content: flex-start
}

@media (min-width: 768px) {

.SignupForm_root__1VZ4l {
    justify-content: space-between
}
  }

.SignupForm_name__3i0hO {
  display: flex;
  justify-content: space-between;
  margin-top: 24px
}

@media (min-width: 768px) {

.SignupForm_name__3i0hO {
    margin-top: 32px
}
  }

.SignupForm_firstNameRoot__1qYXN {
  width: calc(34% - 9px);
}

.SignupForm_lastNameRoot__3CwTD {
  width: calc(66% - 9px);
}

.SignupForm_password__19XXg {
    /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media (min-width: 768px) {

.SignupForm_password__19XXg {
  margin-top: 32px;
}
    }

.SignupForm_bottomWrapper__3rqTa {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.SignupForm_bottomWrapper__3rqTa {
  margin-top: 96px;
}
    }

.SignupForm_bottomWrapper__3rqTa {
  margin-top: 50px;
}

.SignupForm_bottomWrapperText__ns7jj {
  margin-top: -24px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {

.SignupForm_bottomWrapperText__ns7jj {
  padding-bottom: 0;
}
    }

.SignupForm_termsText__2vzfL {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.SignupForm_termsText__2vzfL {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.SignupForm_termsText__2vzfL {
  color: var(--matterColorAnti);
  margin: 0;
}

@media (min-width: 768px) {

.SignupForm_termsText__2vzfL {
  margin: 0;
}
    }

.SignupForm_termsLink__fsalN {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.SignupForm_termsLink__fsalN {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.SignupForm_termsLink__fsalN {
  color: var(--matterColor);
  margin: 0
}

@media (min-width: 768px) {

.SignupForm_termsLink__fsalN {
  margin: 0
}
    }

.SignupForm_termsLink__fsalN:hover {
    text-decoration: underline;
    cursor: pointer;
  }

.SignupForm_country__RYUVn {
  margin-bottom: 1rem;
}

.SignupForm_recaptcha__vbd97 {
  margin-top: 30px;
}

.SignupForm_firstNameTip__3P7eg {
  margin: 0;
  margin-top: 0.5rem;
  line-height: 1.2;
  font-size: 14px;
  color: var(--matterColor);
}

.AuthenticationPage_layoutWrapperMain__3gWAw {
  min-height: calc(100vh - var(--topbarHeight))
}
@media (min-width: 768px) {
.AuthenticationPage_layoutWrapperMain__3gWAw {
    min-height: calc(100vh - var(--topbarHeightDesktop))
}
  }
.AuthenticationPage_root__2RWFg {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
}
@media (min-width: 768px) {
.AuthenticationPage_root__2RWFg {
  justify-content: center;
  align-items: flex-start
}
    }
@media (min-width: 768px) {
.AuthenticationPage_root__2RWFg {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url(/static/media/signup.5adf5db7.png);

    /* Add loading color for the div */
  background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: center center
}
  }
@media (min-width: 768px) and (min-width: 1024px) {
.AuthenticationPage_root__2RWFg {
  border-radius: 40px;
  border: solid 36px var(--matterColorBright)
}
    }
.AuthenticationPage_content__3nHEa {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;
}
@media (min-width: 768px) {
.AuthenticationPage_content__3nHEa {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: var(--modalPaddingMedium);
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid var(--marketplaceColor);
}
    }
.AuthenticationPage_content__3nHEa {
  padding-top: 29px;
}
/* ================ Tabs & Form ================ */
@media (min-width: 768px) {
.AuthenticationPage_tabs__1ppTt {
    margin-top: 6px
}
  }
.AuthenticationPage_tab__1SV2L {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.AuthenticationPage_tab__1SV2L {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0
}
    }
.AuthenticationPage_tab__1SV2L {
  margin-top: 0;
  margin-bottom: 0
}
@media (min-width: 768px) {
.AuthenticationPage_tab__1SV2L {
    margin-top: 0
}
  }
.AuthenticationPage_form__2_HuI {
  margin-top: 24px
}
@media (min-width: 768px) {
.AuthenticationPage_form__2_HuI {
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_loginForm__HEmUQ {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px
}
@media (min-width: 768px) {
.AuthenticationPage_loginForm__HEmUQ {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_signupForm__bEfKv {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px
}
@media (min-width: 768px) {
.AuthenticationPage_signupForm__bEfKv {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_bottomWrapper__2pvFT {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}
@media (min-width: 768px) {
.AuthenticationPage_bottomWrapper__2pvFT {
  margin-top: 96px;
}
    }
.AuthenticationPage_bottomWrapper__2pvFT {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}
/* Terms of Service modal*/
.AuthenticationPage_termsWrapper__uCQwW {
  width: 100%;
  padding-top: 40px
}
@media (min-width: 768px) {
.AuthenticationPage_termsWrapper__uCQwW {
    width: 604px;
    padding-top: 11px
}
  }
.AuthenticationPage_termsHeading__OCaVq {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}
@media (min-width: 768px) {
.AuthenticationPage_termsHeading__OCaVq {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }
.AuthenticationPage_termsHeading__OCaVq {
  margin: 0 0 19px 0
}
@media (min-width: 768px) {
.AuthenticationPage_termsHeading__OCaVq {
    margin: 0 0 19px 0
}
  }
/* ================ Hide Top bar in screens smaller than 768px  ================ */
.AuthenticationPage_hideOnMobile__1jvqT {
  display: none
}
@media (min-width: 768px) {
.AuthenticationPage_hideOnMobile__1jvqT {
    display: block
}
  }
/* ================ Close icon ================ */
.AuthenticationPage_verifyClose__3W4UD {
    /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

    /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_verifyClose__3W4UD {
  padding: 27px 30px;
  position: absolute;
}
    }
.AuthenticationPage_verifyClose__3W4UD {

    /* Colors */
  background-color: transparent;
  color: var(--matterColor);

  cursor: pointer;
}
.AuthenticationPage_verifyClose__3W4UD:enabled:hover {
  background-color: transparent;
  color: var(--matterColorDark);
  box-shadow: none;
  text-decoration: none;
    }
.AuthenticationPage_verifyClose__3W4UD:enabled:active {
  background-color: transparent;
  color: var(--matterColorDark);
    }
.AuthenticationPage_verifyClose__3W4UD:disabled {
  background-color: transparent;
    }
.AuthenticationPage_verifyClose__3W4UD {

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}
.AuthenticationPage_closeText__1c1ZU {
    /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
.AuthenticationPage_closeText__1c1ZU {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }
.AuthenticationPage_closeText__1c1ZU {
  margin: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_closeText__1c1ZU {
  margin: 0;
}
    }
.AuthenticationPage_closeIcon__3n_Wy {
  display: inline-block;
  margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;
}
@media (min-width: 768px) {
.AuthenticationPage_closeIcon__3n_Wy {
  margin-top: 2px;
}
    }
/* ================ Typography ================ */
/* Title of the modal */
.AuthenticationPage_modalTitle__3juH6 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}
@media (min-width: 768px) {
.AuthenticationPage_modalTitle__3juH6 {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0;
}
    }
/* Paragraph for the Modal */
.AuthenticationPage_modalMessage__xFsFe {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 0 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalMessage__xFsFe {
  font-weight: var(--fontWeightRegular);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.11px;
  margin: 24px 0 0 0;
}
    }
/* Make the email pop */
.AuthenticationPage_email__3TlJ6 {
  font-weight: var(--fontWeightHighlightEmail);
}
/* Helper links */
.AuthenticationPage_modalHelperLink__3NCgn {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}
.AuthenticationPage_modalHelperLink__3NCgn:hover {
  text-decoration: underline;
  cursor: pointer;
    }
.AuthenticationPage_modalHelperLink__3NCgn {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperLink__3NCgn {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }
.AuthenticationPage_modalHelperLink__3NCgn {
  color: var(--matterColor);
  margin: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperLink__3NCgn {
  margin: 0;
}
    }
/* Helper texts for the links, not the actual links */
.AuthenticationPage_modalHelperText__-dwS_ {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperText__-dwS_ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }
.AuthenticationPage_modalHelperText__-dwS_ {
  color: var(--matterColorAnti);
  margin: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperText__-dwS_ {
  margin: 0;
}
    }
.AuthenticationPage_modalIcon__1g-gP {
  height: 48px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.AuthenticationPage_modalIcon__1g-gP {
  height: 64px;
  margin-bottom: 23px
}
    }
.AuthenticationPage_modalIcon__1g-gP {

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px
}
@media (min-width: 768px) {
.AuthenticationPage_modalIcon__1g-gP {
    margin-top: 0
}
  }
/* ================ Errors ================ */
.AuthenticationPage_error__1SmGR {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
.AuthenticationPage_error__1SmGR {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }
.AuthenticationPage_error__1SmGR {
  margin-top: 24px;
  color: var(--failColor);
}
/* ================ Social logins & SSO ================ */
.AuthenticationPage_signupWithIdpTitle__2aezm {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.AuthenticationPage_signupWithIdpTitle__2aezm {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0
}
    }
.AuthenticationPage_signupWithIdpTitle__2aezm {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark)
}
@media (min-width: 768px) {
.AuthenticationPage_signupWithIdpTitle__2aezm {
    margin-top: 6px
}
  }
.AuthenticationPage_confirmInfoText__1dEBC {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
.AuthenticationPage_confirmInfoText__1dEBC {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }
.AuthenticationPage_buttonIcon__15Qjf {
  position: absolute;
  left: 0;
  margin-left: 20px;
}
.AuthenticationPage_socialButtonWrapper__bNq0o {
  margin-bottom: 6px
}
@media (min-width: 768px) {
.AuthenticationPage_socialButtonWrapper__bNq0o {
    margin-top: 8px
}
  }
.AuthenticationPage_socialButtonsOr__YbZ6c {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight)
}
.AuthenticationPage_socialButtonsOr__YbZ6c:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
@media (min-width: 768px) {
  .AuthenticationPage_socialButtonsOr__YbZ6c {
    height: 34px;
    margin: 15px 0;
  }
}
.AuthenticationPage_socialButtonsOrText__34yuc {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

